import React, { FC, useCallback, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableCallbackType } from '../../components/table/types/TableCallback';
import { DefaultTableFilter, TableFilterType } from '../../components/table/types/TableFilter';
import { TableFormConfig } from '../../components/table/types/TableForm';
import { TableSearchCol } from '../../components/table/types/TableHttp';
import { TableColumns } from '../../components/table/types/TableRow';
import { dispatchHttpEvent } from '../../events/Http.event';
import Icon from '../../lib/samfe/components/Icon/Icon';
import { currentSqlDate } from '../../lib/samfe/modules/Parse/Date';
import { ElementModel, ElementRelationsBluePrint } from './ElementTypes';
import ElementCompoundForm from './pivot/elementCompound/ElementCompoundForm';
import ElementExcipientForm from './pivot/elementExcipient/ElementExcipientForm';
import useElement from './useElement';


type ElementTableType = 'compound'|'excipient'|'review'|'reference'

type Props = Omit<ExtendTableProps, 'notification'|'parentId'|'htmlBefore'>&{
    forType: ElementTableType,
    forProduct?: boolean,
    extraRelations?: ElementRelationsBluePrint[]
    extraFilter?: DefaultTableFilter<ElementModel>,
    hideType?: boolean,
    hideExtension?: boolean
}


const BaseElementTable: FC<Props> = ({
    forType = 'reference',
    forProduct = false,
    extraRelations = [],
    extraFilter,
    hideType = false,
    hideExtension = false
}) => {

    const httpHook = useElement();

    const linkTo = useCallback((item: ElementModel) => {
        return `/settings/${ !item.compound_id ?'excipient' :'compound' }-elements/${ item.id }`;
    }, []);

    const compoundRows = useMemo((): TableColumns<ElementModel, ElementRelationsBluePrint>[] => [
        {
            header: {
                children: 'Grondstof',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Verbinding'
            },
            column: (item) => ({
                children: item.compound?.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Categorie'
            },
            column: (item) => ({
                children: item.category?.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'RI',
                sortCol: 'intake'
            },
            column: (item) => ({
                children: item.intake
            }),
            type: 'percentage'
        }
    ], []);

    const excipientRows = useMemo((): TableColumns<ElementModel, ElementRelationsBluePrint>[] => [
        {
            header: {
                children: 'Hulpstof',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Functie'
            },
            column: (item) => ({
                children: item.excipient?.name
            }),
            type: 'text'
        }
    ], []);

    const referenceRows = useMemo((): TableColumns<ElementModel, ElementRelationsBluePrint>[] => {
        const rows: TableColumns<ElementModel, ElementRelationsBluePrint>[] = [
            {
                header: {
                    children: 'Element',
                    sortCol: 'name'
                },
                column: (item) => ({
                    children: `${ item.name } ${ !hideExtension ? item.compound?.name ?? item.excipient?.name ?? '': '' }`,
                    linkTo
                }),
                type: 'text'
            }
        ];

        if (!hideType) {
            rows.push({
                header: {
                    children: 'Type',
                    sortCol: 'type'
                },
                column: (item) => ({
                    children: item.type
                }),
                type: 'text'
            });
        }
        return rows;

    }, [ linkTo, hideType, hideExtension ]);

    const reviewRows = useMemo((): TableColumns<ElementModel, ElementRelationsBluePrint>[] => {
        const rows: TableColumns<ElementModel, ElementRelationsBluePrint>[] = [
            {
                header: {
                    children: 'Element',
                    sortCol: 'name'
                },
                column: (item) => ({
                    children: `${ item.name } ${ !hideExtension ? item.compound?.name ?? item.excipient?.name ?? '' : '' }`,
                    linkTo
                }),
                type: 'text'
            },
        ];

        if (!hideType) {
            rows.push({
                header: {
                    children: 'Type',
                    sortCol: 'type'
                },
                column: (item) => ({
                    children: item.type
                }),
                type: 'text'
            });
        }

        rows.push(
            {
                header: {
                    children: 'Aantal risico\'s',
                    sortCount: 'elementRisks'
                },
                column: (item) => ({
                    children: item.elementRisks_count
                }),
                type: 'numeric'
            },
            {
                header: {
                    children: 'Is beoordeeld',
                    sortCol: 'is_reviewed'
                },
                column: (item) => ({
                    children: <Icon materialIconName={ item.is_reviewed ?'check' :'close' }/>
                }),
                type: 'icon'
            },
            {
                header: {
                    children: 'Laatst beoordeeld op',
                    sortCol: 'reviewed_at'
                },
                column: (item) => ({
                    children: item.reviewed_at
                }),
                type: 'date'
            }
        );
        return rows;

    }, [ linkTo, hideType, hideExtension ]);


    const rows: TableColumns<ElementModel, ElementRelationsBluePrint>[] = useMemo(() => {
        let rows: TableColumns<ElementModel, ElementRelationsBluePrint>[] = [];
        switch (forType) {
            case 'compound':
                rows.push(...compoundRows);
                break;
            case 'excipient':
                rows.push(...excipientRows);
                break;
            case 'review':
                rows.push(...reviewRows);
                break;
            case 'reference':
                rows.push(...referenceRows);
                break;
        }

        if (!forProduct || forType != 'reference') {
            rows.push({
                header: {
                    children: 'Voorkomen in product',
                    sortCount: 'compositionProducts'
                },
                column: (item) => ({
                    children: item.compositionProducts_count
                }),
                type: 'numeric'
            });
        }
        return rows;
    }, [ forType, compoundRows, excipientRows, reviewRows, forProduct, referenceRows ]);

    const title = useMemo(() => {
        switch (forType) {
            case 'compound':
                return 'Grondstof';
            case 'excipient':
                return 'Hulpstof';
            default:
                return 'Element';
        }
    }, [ forType ]);


    const filter: DefaultTableFilter<ElementModel>|undefined = useMemo(() => {

        if (([ 'review', 'reference' ] as ElementTableType[]).includes(forType)) {
            return extraFilter;
        }

        let value: number|string = 0;
        if (extraFilter) {
            value = `${ value },${ extraFilter.column }${ extraFilter.operator ?? '=' }${ extraFilter.value }`;
        }
        return {
            column: forType == 'compound' ?'compound_id' :'excipient_id',
            operator: '>',
            value
        };
    }, [ forType, extraFilter ]);


    const filters: TableFilterType<ElementModel>[] = useMemo(() => {
        if (forType == 'review') {
            return [
                {
                    displayName: 'Niet beoordeeld',
                    column: 'is_reviewed',
                    operator: '=',
                    value: 0,
                    default: true
                }
            ];
        }
        return [];
    }, [ forType ]);


    const forms: TableFormConfig<ElementModel, keyof ElementModel>['forms']|undefined = useMemo(() => {
        if (([ 'review', 'reference' ] as ElementTableType[]).includes(forType)) {
            return undefined;
        }

        return {
            edit: {
                Form: forType == 'compound' ?ElementCompoundForm :ElementExcipientForm,
                id: (record) => record.id
            },
            archive: {
                id: (record) => record.id,
                itemName: (record) => {
                    if (forType == 'compound') {
                        return `${ record.name } ${ record.compound?.name ?? '' }`;
                    }
                    return `${ record.name } ${ record.excipient?.name ?? '' }`;
                },
                resourceName: () => title
            }
        } as TableFormConfig<ElementModel, keyof ElementModel>['forms'];
    }, [ forType, title ]);


    const callbacks: TableCallbackType<ElementModel>[] = useMemo(() => {
        if (forType != 'review') {
            return [];
        }
        return [
            {
                title: 'Markeren als beoordeeld',
                hideForRow: (item) => item.is_reviewed == true,
                onClick: (item) => {
                    httpHook.update(item.id, {
                        is_reviewed: true,
                        // @ts-ignore
                        reviewed_at: currentSqlDate()
                    }).then(dispatchHttpEvent);
                }
            },
            {
                title: 'Markeren voor evaluatie',
                hideForRow: (item) => item.is_reviewed == false,
                onClick: (item) => {
                    httpHook.update(item.id, {
                        is_reviewed: false
                    }).then(dispatchHttpEvent);
                }
            }
        ] as TableCallbackType<ElementModel>[];
    }, [ forType, httpHook ]);


    const searchCols: TableSearchCol<ElementModel, ElementRelationsBluePrint>[] = useMemo(() => {
        switch (forType) {
            case 'compound':
                return [ 'name', 'compound.name', 'category.name' ];
            case 'excipient':
                return [ 'name', 'excipient.name' ];
            case 'review':
                return [ 'name', 'excipient.name', 'compound.name', 'category.name' ];
            case 'reference':
                return [ 'name', 'excipient.name', 'compound.name' ];
        }
    }, [ forType ]);


    const withRelations: ElementRelationsBluePrint[] = useMemo(() => {
        switch (forType) {
            case 'compound':
                return [ 'compound', 'category' ];
            case 'excipient':
                return [ 'excipient' ];
            case 'review':
                return [ 'compound', 'excipient', 'category' ];
            case 'reference':
                return [ 'compound', 'excipient' ];
        }
    }, [ forType ]);

    const withCount: ElementRelationsBluePrint[] = useMemo(() => {
        const withCount: ElementRelationsBluePrint[] = [];
        if (forType == 'review') {
            withCount.push('elementRisks');
        }

        if (!forProduct || forType != 'reference') {
            withCount.push('compositionProducts');
        }
        return withCount;
    }, [ forType, forProduct ]);


    return (
        <Table
            id={ title }
            rows={ rows }
            rowActions={ {
                linkTo: forType != 'review' ?linkTo :undefined
            } }
            http={ {
                hook: httpHook,
                searchCols,
                with: [...withRelations, ...extraRelations],
                withCount,
                filter,
                filters,
                filterConfig: {
                    hideArchived: ([ 'review', 'reference' ] as ElementTableType[]).includes(forType)
                },
                disableDeArchiving: ([ 'review', 'reference' ] as ElementTableType[]).includes(forType)
            } }
            forms={ forms }
            callbacks={ callbacks }
        />
    );
};

export default BaseElementTable;
