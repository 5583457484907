import classNames from 'classnames';
import React, { FC, ReactNode, useMemo } from 'react';
import Disclosure from '../../../../../lib/samfe/components/Disclosure/Disclosure';
import Checkbox from '../../../../../lib/samfe/components/Form/Generic/Checkbox';
import Icon from '../../../../../lib/samfe/components/Icon/Icon';
import useAsyncMemo from '../../../../../lib/samfe/hooks/useAsyncMemo';
import { datetimeToLocale } from '../../../../../lib/samfe/modules/Parse/Date';
import useProductRisk from '../../../../product/pivot/productRisk/useProductRisk';
import AssessmentStatusPill from '../../../components/AssessmentStatusPill';
import useAssessmentIndex from '../../../hooks/useAssessmentIndex';
import useAssessmentIsApproved from '../../../hooks/useAssessmentIsApproved';
import { AssessmentHistory } from '../../../types/AssessmentTypes';


type Props = {
    status: ReactNode,
    checkbox?: ReactNode,
    risk: ReactNode,
    frequency: ReactNode,
    requestedAt?: ReactNode,
    approvedAt?: ReactNode
}
const AssessmentListItem: FC<Props> = ({
    status,
    checkbox,
    risk,
    frequency,
    requestedAt,
    approvedAt
}) => {

    return <div className={ 'flex gap-x-4 w-full px-2' }>
        <div className={ 'w-[7rem]' }>
            { status }
        </div>
        <div className={ 'w-12' }>
            <div className={ 'w-fit mx-auto' }>
                { checkbox }
            </div>
        </div>
        <div className={ 'w-[24rem]' }>
            { risk }
        </div>
        <div className={ 'w-[5rem] text-right' }>
            { frequency }
        </div>
        <div className={ 'text-right w-[8rem]' }>
            { requestedAt }
        </div>
        <div className={ 'text-right w-[8rem]' }>
            { approvedAt }
        </div>
    </div>;
};


const AssessmentHeader: FC<{ title: string }> = ({ title }) => <span className={ 'font-medium text-graphite' }>{ title }</span>;
export const AssessmentListHeaders = () => {
    return <div className={ 'pb-1 mb-2' }>
        <AssessmentListItem
            status={ <AssessmentHeader title={ 'Status' }/> }
            risk={ <AssessmentHeader title={ 'Risico' }/> }
            frequency={ <AssessmentHeader title={ 'Frequentie' }/> }
            requestedAt={ <AssessmentHeader title={ 'Aanvraagdatum' }/> }
            approvedAt={ <AssessmentHeader title={ 'Borgingsdatum' }/> }
        />
    </div>;
};


export type AssessmentRequestItemProps = {
    item: AssessmentHistory,
    productId?: number
    active: boolean
    onClick: (assessmentId: number, active: boolean) => void
}

const AssessmentRequestItem: FC<AssessmentRequestItemProps> = ({
    item,
    productId,
    active,
    onClick
}) => {

    const productRiskHook = useProductRisk(productId)
    const isApproved = useAssessmentIsApproved(item);

    const assessmentId = useMemo(() => {
        return item.current.assessment.id;
    }, [ item.current.assessment.id ]);

    const riskName = useMemo(() => {
        return item.risk_name;
    }, [ item.risk_name ]);

    const productRiskId = useMemo(() => {
        return item.product_risk_id;
    }, [ item.product_risk_id ]);

    const index = useAssessmentIndex(item);

    const isBlocked = useMemo(() => {
        return item.current.assessment.blocked ?? false
    }, [item.current.assessment.blocked]);

    const blockedMessage = useAsyncMemo(async () => {
        if (!isBlocked || !productId) {
            return undefined
        }
        return await productRiskHook
            .getItem(productRiskId)
            .then(r => r?.blocked_comment)
    }, [isBlocked, productId])


    const requestedAt = useMemo(() => {
        return item.current.assessment.requested_at;
    }, [ item.current.assessment.requested_at ]);

    const approvedAt = useMemo(() => {
        return item.current.assessment.approved_at;
    }, [ item.current.assessment.approved_at ]);

    return (<div className={ 'mb-2' }>
            <AssessmentListItem
                status={ <AssessmentStatusPill isApproved={ isApproved }/> }

                checkbox={ <Checkbox
                    className={ '!mt-0.5 !mx-none mx-auto' }
                    key={ assessmentId }
                    name={ riskName }
                    checked={ active }
                    onChange={ (activeChanged) => onClick(item.current.assessment.id, activeChanged) }
                /> }

                risk={ <>
                    <span className={ classNames('font-medium text-[0.9rem] block mt-1',
                        item.current.assessment.status == 'test_requested' && 'text-sky',
                        item.current.assessment.status == 'to_test' && 'text-fire',
                        item.current.assessment.status == 'approved' && 'text-green-700/90',
                        item.current.assessment.status == 'not_applicable' && 'text-graphite'
                    ) }>
                        { riskName }
                    </span>
                    {blockedMessage != undefined && <span className={'italic'}>{blockedMessage}</span>}
                </> }

                frequency={ <span className={ 'text-[0.9rem]' }>
                    { item.current.assessment.blocked ?<Icon className={ 'text-carrot relative -top-0.5 pr-1' } materialIconName={ 'warning' }/> :'' } { item.frequency == 0 || index == 0 ?'nvt' :<>{ index }/{ item.frequency }</> }
                </span> }

                requestedAt={ <span className={ 'text-[0.9rem]' }>{ requestedAt ?datetimeToLocale(requestedAt) :'-' }</span> }
                approvedAt={ <span className={ 'text-[0.9rem]' }>{ approvedAt ?datetimeToLocale(approvedAt) :'-' }</span> }
            />
        </div>

    );
};

export default AssessmentRequestItem;


export const AssessmentGroupItem: FC<{
    title: string
    productId?: number
    items: AssessmentRequestItemProps[]
}> = ({ title, productId, items }) => {
    return <Disclosure
        title={ title }
        variation={ 'descriptive' }
        defaultOpen={ true }
        className={ '!px-0 !mx-0 ' }
        titleClassName={ '!-mx-12 !w-[61rem] all-children:!left-0 all-children:!right-0' }
        contentClassName={ '!left-0! right-0 !pl-0 !-mx-10' }
        toggle={ {
            hide: { title: true }
        } }
    >
        <div className={ 'mt-4 !-mx-2' }>
            { items.map((item, i) => <AssessmentRequestItem
                key={ i }
                productId={productId}
                { ...item }
            />) }
            { items.length == 0 && <p className={ 'text-graphite mx-2 -mt-4 font-light' }>Geen resultaten beschikbaar</p> }
        </div>
    </Disclosure>;
};


