import React, { Context, createContext, Dispatch, ReactNode, RefObject, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { Pagination } from '../../../lib/samfe/modules/Http/usePagination';
import useTableUriPagination from '../hooks/useTableUriPagination';
import { PostProcessData, TableProps } from '../types/Table';
import { TableCallbackConfigProp } from '../types/TableCallback';
import { TableRowSortConfig } from '../types/TableRowSort';
import { TableFormConfig } from '../types/TableForm';
import { TableHttpConfig } from '../types/TableHttp';
import { ColumnActionType, TableColumns } from '../types/TableRow';
import { TableStyleConfig } from '../types/TableStyle';


type TableContextType<T extends object, R extends string> =
    TableFormConfig<T, keyof T>
    &TableStyleConfig
    &TableCallbackConfigProp<T>
    &TableRowSortConfig<T>
    &{
    table: {
        id: string,
        rows: TableColumns<T, R>[],
        rowActions: ColumnActionType<T>,
        rowDisabled?: (data: T) => boolean,
        rowClassName?: (data: T,) => string
        containerRef: RefObject<HTMLDivElement>,
        postProcessData?: PostProcessData<T>
        staticData?: T[]
    },
    http: TableHttpConfig<T, R>['http']&{
        dataState: [ T[], Dispatch<SetStateAction<T[]>> ]
    }
}


const defaultTableContext = <T extends object, R extends string>(): TableContextType<T, R> => ({
    http: {
        hook: {
            getItem: async() => undefined,
            getList: async() => [],
            create: async() => undefined,
            update: async() => undefined,
            destroy: async() => undefined,
            pagination: {
                pagination: {
                    currentPage: 0,
                    totalItems: 1,
                    perPage: 25,
                    sortDir: 'ASC',
                    search: undefined,
                    filter: undefined
                },
                setPagination: () => {
                }
            }
        },
        filters: [],
        filter: undefined,
        filterConfig: {
            hideArchived: false,
            hideShowAll: false,
            hideAllFilters: false
        },
        sortDir: 'ASC',
        sortCol: 'id' as keyof object,
        searchCols: [],
        with: [],
        withCount: [],
        disableDeArchiving: false,
        dataState: [
            [], () => {
            }
        ],
        showAll: false
    },
    table: {
        id: '',
        rows: [],
        staticData: undefined,
        rowActions: {
            linkTo: undefined,
            onClick: undefined
        },
        containerRef: {
            current: null
        }
    },
    forms: {
        createPivot: undefined,
        edit: undefined,
        archive: undefined,
        actions: []
    },
    callbacks: [],
    styling: {
        variation: 'menu',
        isPivot: false
    },
    rowSort: undefined
});


const genericTableContext = <T extends object, R extends string>() => {
    const genericContext = defaultTableContext<T, R>();
    return createContext<TableContextType<T, R>>(genericContext);
};
const TableContext = genericTableContext();
//const TableContext = createContext<TableContextType<object, string>>(defaultTableContext<TType<object>, RType<string>>());


export const useTableContext = <T extends object, R extends string>(): TableContextType<T, R> => useContext<TableContextType<T, R>>(
    TableContext as unknown as Context<TableContextType<T, R>>
);


type TableProviderProps<T, R extends string> = TableProps<T, R>&{
    children: ReactNode,
    tableContainerRef: RefObject<HTMLDivElement>
}
const TableProvider = <T extends object, R extends string>({
    http,
    id,
    rows,
    rowDisabled,
    rowClassName,
    children,
    forms,
    callbacks,
    styling,
    rowSort,
    tableContainerRef,
    postProcessData,
    rowActions,
    staticData
}: TableProviderProps<T, R>) => {

    const [ isInitialized, setIsInitialized ] = useState(false);
    const [ pagination, setPagination ] = useState<Pagination<T>>();
    const dataState = useState<T[]>([]);

    const [ uriPagination, setUriPagination ] = useTableUriPagination<T>(id, {
        defaultFilter: http.filter,
        filters: http.filters ?? [],
        sortCol: http.sortCol ?? ('id' as keyof T),
        sortDir: http.sortDir ?? 'ASC',
        showAll: http.showAll ?? false
    });

    const defaultConfig = useMemo(() => defaultTableContext<T, R>(), []);


    useEffect(() => {
        if (isInitialized) {
            return;
        }
        setPagination(uriPagination);
        setIsInitialized(true);
    }, [ uriPagination ]);

    useEffect(() => {
        if (!pagination) {
            return;
        }
        setUriPagination(pagination);
    }, [ pagination ]);

    useEffect(() => {
        if (!isInitialized || !pagination) {
            return;
        }
        setPagination({
            ...pagination,
            totalItems: http.hook.pagination.pagination.totalItems
        });
    }, [ http.hook.pagination.pagination ]);

    //@ts-ignore T to object conversion
    const value: TableContextType<T, R>|null = useMemo(() => {
        if (!pagination) {
            return null;
        }
        return {
            http: {
                hook: {
                    ...http.hook,
                    pagination: { pagination, setPagination }
                },
                filters: http.filters ?? [],
                filter: http.filter,
                searchCols: http.searchCols ?? [],
                with: http.with ?? [],
                withCount: http.withCount ?? [],
                filterConfig: {
                    ...defaultConfig.http.filterConfig,
                    ...http.filterConfig
                },
                disableDeArchiving: http.disableDeArchiving,
                dataState: dataState,
                showAll: http.showAll ?? rowSort?.orderColumn != undefined ?? false,
                whereCount: http.whereCount,
                doesntHave: http.doesntHave,
                whereIn: http.whereIn,
                select: http.select,
                sortCol: http.sortCol,
                sortDir: http.sortDir
            },
            table: {
                id: id,
                rows,
                rowDisabled,
                config: {
                    isPivot: false,
                    filter: {
                        hideArchived: false,
                        hideShowAll: false,
                        hideAllFilters: false
                    }
                },
                rowActions: {
                    ...defaultConfig.table.rowActions,
                    ...rowActions
                },
                rowClassName,
                containerRef: tableContainerRef,
                postProcessData,
                staticData
            },
            forms: {
                ...forms,
                edit: forms?.edit,
                archive: forms?.archive,
                actions: forms?.actions ?? []
            },
            callbacks: callbacks ?? defaultConfig.callbacks,
            styling: {
                variation: 'menu',
                ...styling
            },
            rowSort: rowSort
        } as TableContextType<T, R>;
    }, [ http.hook, http.filter, http.searchCols, http.with, pagination, setPagination, id, rows, forms, callbacks, styling, defaultConfig.callbacks, defaultConfig.http.filterConfig, http.disableDeArchiving, rowSort, http.showAll, http.whereCount, http.doesntHave, http.whereIn, http.select, http.sortCol, http.sortDir, defaultConfig.table.rowActions, rowActions, tableContainerRef, postProcessData, rowDisabled, rowClassName, staticData ]);

    return (
        <>
            {
                // Generic conversion, react context has limited support...
                value && <TableContext.Provider value={ value as unknown as TableContextType<object, string> }>
                    { children }
                </TableContext.Provider> }
        </>
    );
};
export default TableProvider;
