import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from '../../../lib/samfe/components/Form';
import useAsyncInit from '../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../lib/samfe/components/Form/Effects/useSchema';
import { SelectOption } from '../../../lib/samfe/components/Form/Effects/useSelect';
import { optionIsSelected } from '../../../lib/samfe/components/Form/Support/FieldSupport';
import Yup from '../../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../lib/samfe/components/Modal/FormModal/FormModal';
import Alert from '../../../lib/samfe/components/Notifications/Alert';
import { jsDateToSqlDate, sortByDate } from '../../../lib/samfe/modules/Parse/Date';
import { getFullArticleName } from '../../article/ArticleFunctions';
import { ArticleModel } from '../../article/ArticleTypes';
import useArticle from '../../article/useArticle';
import { batchCodeLabel, expirationDateLabel } from '../../charge/ChargeFunctions';
import { ChargeModel } from '../../charge/ChargeTypes';
import useCharge from '../../charge/useCharge';
import { SaleModel } from '../SaleTypes';
import useSale from '../useSale';
import { SaleRowDto, SaleRowModel, SaleRowRelationsBluePrint } from './SaleRowTypes';
import useSaleRow, { useSaleRowResource } from './useSaleRow';


/**
 * @param parentId
 * @param id
 * @param open
 * @param setOpen
 * @param onSuccess
 * @constructor
 */
/** */
const SaleRowForm: FC<ExtendFormModalProps<SaleRowDto>> = ({
    parentId,
    id,
    open,
    setOpen,
    onSuccess
}): JSX.Element => {

    const sale = useSale();
    const saleRow = useSaleRow(parentId);
    const saleRowPivotless = useSaleRowResource();
    const charge = useCharge();

    const saleRowRelations = useMemo((): SaleRowRelationsBluePrint[] => [
        'charge.stock',
        'article.package.parts'
    ], []);

    const [ initialSale, setInitialSale ] = useState<SaleModel>();
    const [ initialSaleRow, setInitialSaleRow ] = useState<SaleRowModel>();
    const [ currentArticle, setCurrentArticle ] = useState<ArticleModel>();
    const [ currentCharge, setCurrentCharge ] = useState<ChargeModel>();
    const [ currentQuantity, setCurrentQuantity ] = useState(0);

    const [ availableCharges, setAvailableCharges ] = useState<ChargeModel[]>([]);
    const [ isBackorder, setIsBackorder ] = useState(true);
    const [ availableQuantity, setAvailableQuantity ] = useState(0);
    const [ predictedPrice, setPredictedPrice ] = useState(0);


    /**
     *
     * @param {number} initialArticleId
     * @returns {Promise<ChargeModel[]>}
     */
    const getAvailableCharges = async(initialArticleId?: number): Promise<ChargeModel[]> => {
        const articleId = initialArticleId;
        if (!articleId) {
            return [];
        }

        const availableCharges = await charge.getList({
            limit: 'all',
            with: [ 'stock' ],
            filter: `amount>0,article_id=${ articleId },expiration_date>${ jsDateToSqlDate() }`
        }).then(charges => charges
            .filter(charge => (charge.stock?.usable_stock ?? 0)>0)
            .sort((a, b) => sortByDate(a.expiration_date, b.expiration_date))
            .sort((a, b) => {
                const aIsFavourite = a.favourite === true;
                const bIsFavourite = b.favourite === true;

                if (aIsFavourite && bIsFavourite) {
                    return 0;
                }
                return aIsFavourite ?-1 :1;
            })
        );


        if (
            !(!currentCharge)
            && initialSaleRow?.charge_id == currentCharge.id
            && currentCharge?.article_id == articleId
            && availableCharges.find(c => c?.id === currentCharge.id) === undefined
        ) {
            availableCharges.push(currentCharge);
        }

        return availableCharges;
    };

    const getPredictedPrice = async(articleId: number): Promise<number> => {
        if (!initialSale) {
            return 0;
        }

        // @todo maybe switch with sale + sale row relation search for eliminating current sale usage
        return await saleRowPivotless.getList({
            with: [ 'sale' ],
            filter: `article_id=${ articleId }`,
            orderBy: 'id',
            order: 'DESC',
            limit: 1,
            relationSearch: [
                {
                    relationCol: 'sales.customer_id',
                    value: `${ initialSale.customer_id }`
                }
            ]
        }).then(rows => {
            if (rows.length === 0) {
                return 0;
            }

            return rows[0].price_per_amount ?? 0;
        });
    };


    const handleArticleChange = (article?: ArticleModel) => {
        if (id !== undefined) {
            return;
        }

        // Switch current article and set charge to undefined if article is switched.
        setCurrentArticle(article);
        if (!article?.id) {
            setCurrentCharge(undefined);
            setIsBackorder(true);
            setAvailableQuantity(Number.MAX_SAFE_INTEGER);
            setAvailableCharges([]);
            return;
        }

        const articleIsSwitched = article.id !== currentCharge?.article_id;
        if (!(!currentCharge) && articleIsSwitched) {
            setCurrentCharge(undefined);
        }

        getPredictedPrice(article.id).then(setPredictedPrice);

        getAvailableCharges(article.id).then(availableCharges => {
            setAvailableCharges(availableCharges);
            if (!id && availableCharges.length>0 && articleIsSwitched) {
                const preferredCharge = availableCharges[0];
                setCurrentCharge(preferredCharge);
                setIsBackorder(false);
                setAvailableQuantity(preferredCharge.stock?.usable_stock ?? 0);
            }
        });
    };


    const handleChargeChange = (chargeId?: number) => {

        // Check if already selected or backorder selected where current charge is undefined.
        if (chargeId == currentCharge?.id || (!currentCharge && chargeId === -1)) {
            return;
        }

        const selectedCharge = availableCharges.find(c => c?.id === chargeId);
        setCurrentCharge(selectedCharge);
        setIsBackorder(!selectedCharge);

        if (!selectedCharge) {
            setAvailableQuantity(Number.MAX_SAFE_INTEGER);
            return;
        }

        const availableChargeStock = selectedCharge.stock?.usable_stock ?? 0;

        // Add initial amount if same charge to reclaim
        const totalAvailable = selectedCharge.id === initialSaleRow?.charge_id
                               ?availableChargeStock + (initialSaleRow?.quantity ?? 0)
                               :availableChargeStock;

        setAvailableQuantity(totalAvailable);
    };


    /**
     *
     * @param {ChargeModel} chargeModel
     * @returns {string}
     */
    const getChargeDisplayName = (chargeModel: ChargeModel): JSX.Element => {
        const expDate = `[${ expirationDateLabel(chargeModel?.expiration_date) }]`;
        const batchCode = batchCodeLabel(chargeModel);
        const stock = `(${ chargeModel?.stock?.usable_stock ?? 0 })`;
        return <>{ expDate } { batchCode } { stock }</>;
    };


    /**
     *
     * @returns {undefined | JSX.Element}
     */
    const getBackOrderWarning = (): undefined|JSX.Element => <>{ currentArticle && !currentCharge && <Alert
        type={ 'warning' }
        message={ 'Deze verkoopregel is een backorder.' }
    /> }
        { id && <strong className={ 'block pt-2' }>{ getFullArticleName(currentArticle) }</strong> }
    </>;

    /**
     *
     */
    const shape = (initialSaleRow?: SaleRowModel, availableCharges: ChargeModel[] = []): Shape<SaleRowDto> => ({

        sale_id: Yup.number()
            .inputType('hidden')
            .controlType('input'),

        article_id: Yup.number()
            .label('Artikel')
            .required()
            .hidden(id !== undefined)
            .controlType('selectSearch')
            .selectSearchConfig({
                useHook: useArticle,
                expectsInitialModel: false,
                onChange: handleArticleChange,
                searchOptions: {
                    relations: [ 'product', 'package.parts' ],
                    searchCols: [ 'number' ],
                    relationSearch: [
                        {
                            relationCol: 'product.name'
                        }
                    ],
                    valueCol: 'id',
                    limit: 'all',
                    filter: 'active=1',
                    displayName: getFullArticleName
                }
            }),

        charge_id: Yup.number()
            .label('Charge')
            .controlType('select')
            .handleValueChange(handleChargeChange)
            .options([
                ...availableCharges.map((charge, i) => ({
                    displayName: getChargeDisplayName(charge),
                    value: charge.id,
                    selected: isBackorder ?false :optionIsSelected(currentCharge?.id, charge.id, i)
                }) as SelectOption),
                {
                    displayName: 'Backorder',
                    value: -1,
                    selected: isBackorder
                }
            ]),


        quantity: Yup.number()
            .label(`Aantal stuks ${ currentCharge ?`(max. ${ availableQuantity } stuks)` :'' }`)
            .required()
            .controlType('input')
            .inputType('number')
            .defaultValue(currentQuantity)
            .handleValueChange(quantity => setCurrentQuantity(quantity ?? 0))
            .steps(1)
            .min(0)
            .max(availableQuantity, currentCharge
                                    ?'Charge heeft niet genoeg voorraad, selecteer een andere charge of backorder.'
                                    :undefined
            ),

        price_per_amount: Yup.number()
            .label(`Prijs per stuk`)
            .description('Per 1000 voor bulk')
            .inputType('number')
            .required()
            .controlType('input')
            .steps(0.01)
            .min(0)
            .defaultValue(predictedPrice),

        colli: Yup.number()
            .label('Colli')
            .inputType('number')
            .required()
            .controlType('input')
            .steps(1)
            .min(1)
            .defaultValue(1),

        customer_product_reference: Yup.string()
            .controlType('input')
            .label('Klant product referentienummer')
            .optional()
            .inputType('text')
            .defaultValue(initialSaleRow?.customer_product_reference ?? ''),

        status: Yup.string()
            .controlType('input')
            .label('Status')
            .hidden(true)
            .inputType('text')
            .defaultValue(isBackorder ?'backorder' :initialSaleRow?.status ?? 'to send')
            .required(),

        wc_product_id: Yup.number()
            .label('WooCommerce ID')
            .hidden(true)
            .inputType('number')
            .controlType('input'),

        sku: Yup.string()
            .hidden(true)
            .controlType('input')
            .inputType('hidden')
    });


    /**
     *
     */
    const { validationSchema, setShape } = useSchema<SaleRowDto>(shape());


    useEffect(() => {
        setShape(shape(initialSaleRow, availableCharges));
    }, [ availableCharges, currentCharge ]);


    const initializer = async() => {
        await sale.getItem(parentId).then(setInitialSale);
        await saleRow.getItem(id, { with: saleRowRelations })
            .then(async(initialSaleRow) => {
                setInitialSaleRow(initialSaleRow);
                setCurrentArticle(initialSaleRow?.article);
                setCurrentCharge(initialSaleRow?.charge);
                setCurrentQuantity(initialSaleRow?.quantity ?? 0);
                setPredictedPrice(initialSaleRow?.price_per_amount ?? 0);
                setIsBackorder(!initialSaleRow?.charge_id);

                const initialCharge = initialSaleRow?.charge;
                const usableStock = initialCharge?.stock?.usable_stock ?? 0;
                const initialQuantity = initialSaleRow?.quantity ?? 0;
                setAvailableQuantity(initialSaleRow !== undefined
                                     ?usableStock + initialQuantity
                                     :Number.MAX_SAFE_INTEGER
                );

                const availableCharges: ChargeModel[] = await getAvailableCharges(initialSaleRow?.article_id);
                const initialChargeIsAvailable = availableCharges.find(c => c.id === initialCharge?.id) !== undefined;
                if (!initialChargeIsAvailable && !(!initialCharge)) {
                    availableCharges.push(initialCharge);
                }
                setAvailableCharges(availableCharges);
                setShape(shape(initialSaleRow, availableCharges));
            });
    };


    /**
     *
     */
    const { formikConfig, formFields } = useForm<SaleRowModel, SaleRowDto, SaleRowRelationsBluePrint>({
        id,
        validationSchema,
        useHttpHook: useSaleRow,
        relations: saleRowRelations,
        parentId: parentId,
        onSuccess: onSuccess,
        initializer,
        initialized: useAsyncInit(initializer, open),
        morphPayload: (_, dto) => {

            const newDto = ({
                ...dto,
                sale_id: parentId,
                sku: currentArticle?.number,
                price_per_amount: parseFloat(`${ dto.price_per_amount ?? initialSaleRow?.price_per_amount }`)
            });

            if (initialSaleRow?.status == 'backorder' && dto.charge_id != null && initialSaleRow?.charge_id == null) {
                newDto.status = 'to send';
            }

            if (isBackorder) {
                //@ts-ignore
                newDto.charge_id = null;
                newDto.status = 'backorder';
            }

            return newDto;
        }
    });

    return <FormModal
        id={ id }
        parentId={ parentId }
        resource={ 'Verkoopregel' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
        htmlBeforeForm={ getBackOrderWarning() }
    />;
};

export default SaleRowForm;
