import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import Modal from '../../../../lib/samfe/components/Modal';
import FormModalFooter from '../../../../lib/samfe/components/Modal/FormModal/FormModalFooter';
import useAsyncMemo from '../../../../lib/samfe/hooks/useAsyncMemo';
import useCharge from '../../../charge/useCharge';
import AssessmentRequestGuide from './components/AssessmentRequestGuide';
import { AssessmentGroupItem, AssessmentListHeaders, AssessmentRequestItemProps } from './components/AssessmentRequestItem';
import { assessmentRequested } from '../../hooks/useAssessmentIsRequested';
import useNotApplicableAssessments from '../../hooks/useNotApplicableAssessments';
import useOptionalAssessments from '../../hooks/useOptionalAssessments';
import useRequiredAssessments from '../../hooks/useRequiredAssessments';
import useCancelAssessment from './http/useCancelAssessment';
import useChargeAssessment from '../../http/useChargeAssessment';
import useRequestAssessment from './http/useRequestAssessment';
import { AssessmentHistory, GroupedAssessmentHistory } from '../../types/AssessmentTypes';


type Props = {
    chargeId: number,
    openState: [ boolean, Dispatch<SetStateAction<boolean>> ],
    onSave?: () => (void|Promise<void>)
}

const AssessmentRequestForm: FC<Props> = ({
    chargeId,
    openState,
    onSave
}) => {

    // Hooks

    const chargeAssessments = useChargeAssessment(chargeId);
    const chargeHook = useCharge();
    const requestAssessments = useRequestAssessment();
    const cancelAssessments = useCancelAssessment();
    const requiredAssessments = useRequiredAssessments();
    const optionalAssessments = useOptionalAssessments();
    const notApplicableAssessments = useNotApplicableAssessments();


    // States

    const [ groupedAssessmentHistory, setGroupedAssessmentHistory ] = useState<GroupedAssessmentHistory>();
    const [ currentActiveIds, setCurrentActiveIds ] = useState<number[]>([]);
    const [ open, setOpen ] = useMemo(() => openState, [ openState ]);


    // Init data
    useEffect(() => {
        if (!open) {
            requiredAssessments.setList([]);
            optionalAssessments.setList([]);
            notApplicableAssessments.setList([]);
            setGroupedAssessmentHistory(undefined);
            setCurrentActiveIds([]);
            return;
        }
        chargeAssessments.getItem().then(setGroupedAssessmentHistory);
    }, [ open ]);


    const productId = useAsyncMemo(async () => {
        if (!open) {
            return undefined
        }
        return await chargeHook.getItem(chargeId).then(r => r?.product_id)
    }, [open, chargeId])

    // Assessment states

    //noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
    /**
     * Process assessment states, find initial active ids and set current active ids.
     * @type {number[]}
     */
    const initialActiveIds: number[] = useMemo(() => {

        const items = groupedAssessmentHistory?.assessmentHistory ?? [];

        requiredAssessments.setList(items);
        optionalAssessments.setList(items);
        notApplicableAssessments.setList(items);

        const initialActive = items
            .filter(assessmentRequested)
            .map(item => item.current.assessment.id);

        setCurrentActiveIds(initialActive);
        return initialActive;
    }, [ groupedAssessmentHistory?.assessmentHistory ]);

    /**
     * Check if numbers were initially active and currently NOT, if so -> cancel assessment request
     * @type {number[]}
     */
    const cancelableIds: number[] = useMemo(() => {
        return initialActiveIds.filter(id => !currentActiveIds.includes(id));
    }, [ initialActiveIds, currentActiveIds ]);

    /**
     * Check if numbers were initially NOT active and currently ARE active, if so -> mark for assessment request
     * @type {number[]}
     */
    const requestableIds: number[] = useMemo(() => {
        return currentActiveIds.filter(id => !initialActiveIds.includes(id));
    }, [ initialActiveIds, currentActiveIds ]);


    // Identifiers

    const batchCode = useMemo(() => {
        return groupedAssessmentHistory?.batchcode ?? '';
    }, [ groupedAssessmentHistory?.batchcode ]);

    const productName = useMemo(() => {
        return groupedAssessmentHistory?.product_name ?? '';
    }, [ groupedAssessmentHistory?.product_name ]);

    const articleNumber = useMemo(() => {
        return groupedAssessmentHistory?.article_number ?? '';
    }, [ groupedAssessmentHistory?.article_number ]);


    // Handlers

    const handleChange = (assessmentId: number, active: boolean) => {
        if (active) {
            setCurrentActiveIds([ ...new Set([ ...currentActiveIds, assessmentId ]) ]);
            return;
        }
        setCurrentActiveIds(currentActiveIds.filter(activeId => activeId != assessmentId));
    };

    const handleCancel = (): void => {
        setOpen(false);
    };

    const handleSave = async() => {
        await Promise.all([
            requestAssessments(requestableIds),
            cancelAssessments(cancelableIds)
        ]).then(onSave).finally(handleCancel);
    };


    // Request items

    const listRequestItems = (items: AssessmentHistory[], activeIds: number[]): AssessmentRequestItemProps[] => items.map(item => ({
        item,
        active: activeIds.includes(item.current.assessment.id),
        onClick: handleChange
    }) as AssessmentRequestItemProps);

    const requiredRequestItems: AssessmentRequestItemProps[] = useMemo(() => {
        return listRequestItems(requiredAssessments.items, currentActiveIds);
    }, [ requiredAssessments.items, currentActiveIds ]);

    const optionalRequestItems: AssessmentRequestItemProps[] = useMemo(() => {
        return listRequestItems(optionalAssessments.items, currentActiveIds);
    }, [ optionalAssessments.items, currentActiveIds ]);

    const notApplicableRequestItems: AssessmentRequestItemProps[] = useMemo(() => {
        return listRequestItems(notApplicableAssessments.items, currentActiveIds);
    }, [ notApplicableAssessments.items, currentActiveIds ]);


    return (
        <Modal
            title={ `Analyse aanvragen voor charge ${ batchCode }` }
            open={ open }
            setOpen={ setOpen }
            className={ 'sm:!w-[64rem] sm:!max-w-none sm:mx-8' }
            footer={ <FormModalFooter
                save={ { onClick: handleSave } }
                onCancelClick={ handleCancel }
            /> }
        >

            <AssessmentRequestGuide batchCode={ batchCode }/>

            <div className={ 'my-6' }>
                <h2 className={ 'text-lg font-medium text-graphite' }>{ productName }</h2>
                <h3 className={ 'text-sm font-medium text-graphite' }>{ articleNumber }</h3>
            </div>

            <AssessmentListHeaders/>
            <AssessmentGroupItem productId={productId} title={ 'Verplicht' } items={ requiredRequestItems }/>
            <AssessmentGroupItem productId={productId} title={ 'Optioneel' } items={ optionalRequestItems }/>
            <AssessmentGroupItem productId={productId} title={ 'Niet van toepassing' } items={ notApplicableRequestItems }/>
        </Modal>
    );
};

export default AssessmentRequestForm;