import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { TableNotification } from '../../components/table/types/Table';
import { dispatchHttpEvent } from '../../events/Http.event';
import Jumbotron from '../../lib/samfe/components/Jumbotron/Jumbotron';
import MailFileModal from '../../lib/samfe/components/Modal/MailFileModal';
import Stats from '../../lib/samfe/components/Stats';
import { datetimeToLocale } from '../../lib/samfe/modules/Parse/Date';
import { toEuroString, toPercentageString } from '../../lib/samfe/modules/Parse/Number';
import useId from '../../lib/samfe/modules/Router/useId';
import ShowPage, { CustomButtonAction } from '../../templates/pages/ShowPage';
import PdfFile from '../document/PdfFile';
import { CompanyInfoModel } from '../group/GroupTypes';
import useGroup from '../group/useGroup';
import CoaTable from './docs/CoaTable';
import ProductDossierTable from './docs/ProductDossierTable';
import SaleRowForm from './pivot/SaleRowForm';
import SaleRowTable from './pivot/SaleRowTable';
import SaleForm from './SaleForm';
import SaleSentModal from './SaleSentModal';
import { SaleModel, SaleStatus, SaleStatusTranslation } from './SaleTypes';
import useSale from './useSale';


const SaleShow: FC = (): JSX.Element => {

    const id = useId();
    const groupHook = useGroup();

    const [ currentSale, setCurrentSale ] = useState<SaleModel>();
    const [ companyInfo, setCompanyInfo ] = useState<CompanyInfoModel>();
    const [ showPackSlipSendModal, setShowPackSlipSendModal ] = useState(false);
    const [ openSaleRowCreateForm, setOpenSaleRowCreateForm ] = useState(false);
    const [ showSaleSentModal, setShowSaleSentModal ] = useState(false);

    useEffect(() => {
        if (!currentSale) {
            return;
        }
        groupHook.getCompanyInfoByUserGroup().then(setCompanyInfo);
    }, [ currentSale ]);

    const groupId = useMemo(() => companyInfo?.group_id ?? undefined, [ companyInfo?.group_id ]);
    const detailView = useMemo(() => companyInfo?.detail_view == true, [ companyInfo?.detail_view ]);
    const packslipIsLocked = useMemo(() => !groupId || !detailView, [ groupId, detailView ]);

    const packSlipVariation = useMemo(() => {
        if (packslipIsLocked) {
            return '';
        }
        return `${ detailView ?'✅' :'❌' } Gedetailleerde pakbon`;
    }, [ packslipIsLocked, detailView ]);


    const toggleDetailView = useCallback(() => {
        if (packslipIsLocked || !groupId || !companyInfo) {
            return;
        }

        groupHook.update(groupId, { detail_view: !detailView })
            .then(r => {
                setCompanyInfo({
                    ...companyInfo,
                    detail_view: r?.detail_view
                });
            });
    }, [ packslipIsLocked, groupId, companyInfo, groupHook, detailView ]);


    const title = useMemo(() => {
        return <>
            Verkooporder { currentSale?.id ?? '' }
            { currentSale?.wc_sale_id && <small>&nbsp;(WC order { currentSale?.wc_sale_id })</small> }
        </>;
    }, [ currentSale?.id, currentSale?.wc_sale_id ]);


    const customButtonActions = useMemo(() => {
        const actions: CustomButtonAction[] = [];
        if (!currentSale?.status) {
            return actions;
        }

        const testableStatuses = ([ 'to send', 'backorder', 'sent with backorders' ] as SaleStatus[]);

        const saleIsOpen = testableStatuses.includes(currentSale.status);
        const hasOpenRows = (currentSale.saleRows ?? [])
            .find(sr => testableStatuses.includes(sr.status ?? 'sent')) !== undefined;

        if (hasOpenRows || saleIsOpen) {
            actions.push(
                {
                    title: 'Verzenden',
                    onClick: () => {
                        setShowSaleSentModal(true);
                    }
                },
                {
                    title: 'Orderregel toevoegen',
                    onClick: () => setOpenSaleRowCreateForm(true)
                }
            );
        }
        return actions;
    }, [ currentSale?.status, currentSale?.saleRows ]);


    const customer = useMemo(() => {
        let customer = currentSale?.customer?.company;
        if (!customer) {
            customer = `${ currentSale?.customer?.first_name ?? '' } ${ currentSale?.customer?.last_name ?? '' }`;
        }
        if (currentSale?.customer?.number) {
            return `${ customer } (${ currentSale?.customer?.number })`;
        }

        return <Link to={ `/customers/${ currentSale?.customer_id }` }>
            { customer }
        </Link>;
    }, [ currentSale?.customer?.company, currentSale?.customer?.first_name, currentSale?.customer?.last_name, currentSale?.customer?.number, currentSale?.customer_id ]);


    const noSaleRowsNotification: TableNotification|undefined = useMemo(() => {
        const saleRowCount = (currentSale?.saleRows??[])
            .filter(sr => sr.archived != true).length
        if (saleRowCount > 0) {
            return
        }

        return {
            title: 'Geen verkoopregels aanwezig',
            variation: 'warning'
        } as TableNotification;
    }, [ currentSale?.saleRows ]);


    const customerNote = useMemo(() => {
        if (!currentSale?.customer_note) {
            return undefined;
        }
        return <div className={ 'my-4' }>
            <Jumbotron title={ 'Klant notitie' } description={ currentSale?.customer_note }/>
        </div>;
    }, [ currentSale?.customer_note ]);


    const stats = useMemo(() => {
        return <Stats stats={ [
            {
                name: 'Klant',
                value: customer
            },
            {
                name: 'Besteldatum',
                value: datetimeToLocale(currentSale?.ordered_at)
            },
            {
                name: 'Verzenddatum',
                value: datetimeToLocale(currentSale?.shipped_at)
            },
            {
                name: 'Status',
                value: SaleStatusTranslation(currentSale?.status)
            },
            {
                name: 'Inkoop',
                value: toEuroString(currentSale?.financialData?.purchase_price)
            },
            {
                name: `Omzet`,
                value: <>
                  { toEuroString(currentSale?.financialData?.total) }
                </>
            },
            {
                name: 'Verwerkte korting',
                value: <>
                  { toPercentageString(currentSale?.financialData?.discount_percentage) }
                   { (currentSale?.financialData?.discount ?? 0)>0 && <small className={ 'block mt-2 text-gray-600' }>
                        ({ toEuroString(currentSale?.financialData?.discount) } korting)
                    </small> }
                </>
            },
            {
                name: 'Winstmarge in de verkoopprijs',
                value: <>
                    { toPercentageString(currentSale?.financialData?.profit_margin_percentage) }
                    <small className={ 'block mt-2 text-gray-600' }>
                        { toEuroString(currentSale?.financialData?.profit_margin) }
                    </small>
                </>
            },
            {
                name: 'Klant referentienummer',
                value: currentSale?.customer_reference ?? '-'
            }
        ] }/>;
    }, [ customer, currentSale?.ordered_at, currentSale?.shipped_at, currentSale?.status, currentSale?.financialData?.purchase_price, currentSale?.financialData?.total, currentSale?.financialData?.discount, currentSale?.financialData?.profit_margin, currentSale?.customer_reference ]);


    const htmlBeforeTable = useMemo(() => {
        return <>
            {stats}
            {customerNote}
        </>
    }, [ stats, customerNote ]);


    return <>
        <ShowPage
            title={ title }
            breadcrumb={ `Verkooporder ${ currentSale?.id }` }
            setCurrentModel={ setCurrentSale }
            relations={ [ 'customer', 'saleRows.article', 'financialData' ] }
            FormModal={ SaleForm }
            httpHook={ useSale }
            counts={ [ 'saleRows' ] }
            customButtonActions={ customButtonActions }
            customActions={ [
                {
                    name: 'Pakbon versturen',
                    icon: 'book',
                    callback: () => setShowPackSlipSendModal(true)
                }
            ] }

            tabs={ [
                {
                    id: 'overview',
                    name: 'Overzicht',
                    element: <>
                        {currentSale?.id && <SaleRowTable
                            parentId={ currentSale.id }
                            notification={noSaleRowsNotification}
                            htmlBefore={htmlBeforeTable}
                        />}
                    </>
                },
                {
                    id: 'packing-slip',
                    name: 'Pakbon',
                    element: <PdfFile
                        id={ id }
                        fileType={ 'packslip' }
                        customFileName={ `pakbon-${ id }` }
                        customActions={ [
                            {
                                name: 'Pakbon verzenden',
                                callback: () => {
                                    setShowPackSlipSendModal(true);
                                }
                            },
                            {
                                name: packSlipVariation,
                                callback: () => toggleDetailView()
                            }
                        ] }
                    />
                },
                {
                    id: 'coa',
                    name: 'COA\'s',
                    element: <CoaTable parentId={ id }/>
                },
                {
                    id: 'productdossier',
                    name: 'Product Dossiers',
                    element: <ProductDossierTable parentId={ id }/>
                }
            ] }
        />

        { showPackSlipSendModal && <MailFileModal
            title={ 'Pakbon verzenden' }
            open={ showPackSlipSendModal }
            setOpen={ setShowPackSlipSendModal }
            mail={ {
                defaultRecipients: [ 'administratie@triplepharma.nl' ],
                defaultSubject: `Pakbon voor verkooporder ${ id }`
            } }
            file={ {
                id,
                type: 'packslip',
                name: `Pakbon voor verkooporder ${ id }`
            } }
        /> }

        { openSaleRowCreateForm && <SaleRowForm
            open={ openSaleRowCreateForm }
            setOpen={ setOpenSaleRowCreateForm }
            parentId={ id }
            onSuccess={ dispatchHttpEvent }
        /> }


        { showSaleSentModal && currentSale && <SaleSentModal
            open={ showSaleSentModal }
            setOpen={ setShowSaleSentModal }
            id={ currentSale.id }
            onClose={ dispatchHttpEvent }
        /> }
    </>;

};
export default SaleShow;