import React, {FC, useEffect, useState} from 'react';
import { useForm } from '../../../../lib/samfe/components/Form';
import useAsyncInit from '../../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import { sqlDateTimeToDate } from '../../../../lib/samfe/modules/Parse/Date';
import usePurchase from '../../usePurchase';
import { PurchaseRowDto, PurchaseRowModel } from '../purchaseRow/PurchaseRowTypes';
import usePurchaseRow from '../purchaseRow/usePurchaseRow';
import {BookInDto, BookInFields, BookInModel, BookInRelationBluePrint} from './BookInTypes';
import useBookIn from './useBookIn';
import useAxios from "../../../../lib/samfe/modules/Http/useAxios";
import DynamicSearch from "../../../../lib/samfe/components/Form/Field/SelectSearch/DynamicSearch";
import {LocationSearchResult} from "../../../location/ChargeLocationTypes";


/** @info what is parent id in this context? */
const BookInForm: FC<ExtendFormModalProps<PurchaseRowDto>> = ({ parentId, id, open, setOpen, onSuccess }): JSX.Element => {

    const purchaseRow = usePurchaseRow(parentId);
    const purchase = usePurchase();
    const axios = useAxios();

    const [currentRow, setCurrentRow] = useState<PurchaseRowModel | undefined>(undefined);
    const [selectedOptions, setSelectedOptions] = React.useState<LocationSearchResult[]>([]);
    const [versions, setVersions] = useState<string[]>([]);

    const shape = (): Shape<BookInDto> => ({

        article_id: Yup.number()
            .inputType('hidden')
            .controlType('input')
            .defaultValue(currentRow?.article_id ?? 0),

        purchase_row_id: Yup.number()
            .inputType('hidden')
            .controlType('input')
            .defaultValue(id ?? 0),

        batchcode: Yup.string()
            .label('Batchcode')
            .required()
            .inputType('text')
            .controlType('input')
            .defaultValue(currentRow?.charge?.batchcode ?? ''),

        version: Yup.string()
            .label('Versie')
            .inputType('text')
            .controlType('select')
            .options(versions.map(version => ({
                displayName: version,
                value: version
            }))),

        locations: Yup.number()
            .hidden(true),

        packaging_slip: Yup.string()
            .required()
            .label('Boekingsnummer')
            .inputType('text')
            .controlType('input')
            .defaultValue(currentRow?.charge?.packaging_slip ?? ''),

        amount: Yup.number()
            .label('Aantal')
            .required()
            .inputType('number')
            .controlType('input')
            .defaultValue(currentRow?.quantity ?? 0),

        price_per_amount: Yup.number()
            .label('Prijs per stuk')
            .description('Per 1000 voor bulk')
            .required()
            .inputType('number')
            .controlType('input')
            .defaultValue(parseFloat(`${ currentRow?.price_per_amount ?? 0 }`)),

        expiration_date: Yup.string()
            .label('THT')
            .required()
            .inputType('date')
            .defaultValue(sqlDateTimeToDate())
            .controlType('input'),

        order_date: Yup.string()
            .label('Besteldatum')
            .required()
            .hidden(true)
            .inputType('date')
            .controlType('input')
            .defaultValue(sqlDateTimeToDate(currentRow?.purchase?.order_date)),

        pack_slip_note: Yup.string()
            .label('Pakbon Notitie')
            .controlType('textArea'),

        charge_receive_date: Yup.string()
            .label('Inboekdatum')
            .required()
            .inputType('date')
            .controlType('input')
            .defaultValue(sqlDateTimeToDate())
    });

    /**
     *
     */
    const { validationSchema, setShape } = useSchema<BookInDto>(shape());

    useEffect(() => {
        setShape(shape());
    }, [versions, currentRow]);

    const initializer = async() => {
        const row = await purchaseRow
            .getItem(id, { with: [ 'charge', 'purchase' ] });

        setCurrentRow(row);

        const versionList = await axios.get<{items: string[]}>(`/articles/${row?.article_id}/versions?limit=100`).then(
            result =>  result.data?.items ?? []
        )

        setVersions(versionList.reverse());
    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<BookInModel, BookInDto, BookInRelationBluePrint>({
        id,
        validationSchema,
        parentId,
        onSuccess: async(chargeId: number|undefined) => {

            // Mark associated row as booked in.
            await purchaseRow.update(id!, {
                status: 'booked'
            }).then(async() => {
                // Check if all rows of purchase are booked in -> mark as booked.
                await purchase.getItem(parentId, { with: [ 'purchaseRows' ] })
                    .then(async purchaseModel => {
                        let isBookedIn = true;
                        for (const purchaseRowModel of (purchaseModel?.purchaseRows ?? [])) {
                            if (purchaseRowModel?.status != 'booked') {
                                isBookedIn = false;
                                break;
                            }
                        }

                        if (isBookedIn) {
                            await purchase.update(parentId!, {
                                status: 'booked'
                            });
                        }
                    });
            }).finally(() => {
                onSuccess?.(chargeId);
            });

        },
        morphPayload: (formikValues, dto) => {
            const payload = {
                ...dto
            };
            payload.locations = selectedOptions

            return {...payload} as Partial<BookInFields>;
        },
        useHttpHook: useBookIn,
        skipInitialFetch: true,
        staticFormMethod: 'POST',
        initializer,
        initialized: useAsyncInit(initializer, open)
    });


    return <FormModal
        id={ id }
        parentId={ parentId }
        resource={ 'Inkoopregel inboeken' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
        operationName={ '' }
        htmlAfterForm={ <DynamicSearch label={'Locaties'} config={
            {
                searchResource: "search/locations",
                values: selectedOptions,
                setValues: setSelectedOptions,
                initialDisplay: undefined,
            }
        } />}
    />;
};

export default BookInForm;
