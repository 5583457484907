import React, { FC, useMemo } from 'react';
import Table from '../../../components/table';
import { ExtendTableProps } from '../../../components/table/types/Table';
import { TableColumns } from '../../../components/table/types/TableRow';
import { sqlDateDiffToString } from '../../../lib/samfe/modules/Parse/Date';
import { ucFirst } from '../../../lib/samfe/modules/Parse/String';
import { batchCodeLabel } from '../../charge/ChargeFunctions';
import { SaleRowModel, SaleRowRelationsBluePrint, SaleRowStatusTranslation } from '../../sale/pivot/SaleRowTypes';
import { useSaleRowResource } from '../../sale/pivot/useSaleRow';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ArticleSaleRowTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useSaleRowResource();


    const rows = useMemo((): TableColumns<SaleRowModel, SaleRowRelationsBluePrint>[] => [
        {
            header: {
                children: 'Ordernummer',
                sortCol: 'sale_id'
            },
            column: (item) => ({
                children: item.sale_id
            }),
            type: 'id'
        },
        {
            header: {
                children: 'Klant'
            },
            column: (item) => {
                let recipient = item.sale?.customer?.company;
                if (!recipient) {
                    const firstName = ucFirst(item.sale?.customer?.first_name);
                    const lastName = ucFirst(item.sale?.customer?.last_name);
                    const spacer = firstName.length>0 && lastName.length>0 ?' ' :'';
                    recipient = `${ firstName }${ spacer }${ lastName }`;
                }
                const customerNumber = item.sale?.customer?.number ?? 0;
                return {
                    children: `${ recipient } (${ customerNumber })`
                };
            },
            type: 'text'
        },
        {
            header: {
                children: 'Charge'
            },
            column: (item) => ({
                children: batchCodeLabel(item.charge)
            }),
            type: 'element'
        },
        {
            header: {
                children: 'Besteldatum'
            },
            column: (item) => ({
                children: item.sale?.ordered_at
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Verzenddatum'
            },
            column: (item) => ({
                children: item.sale?.shipped_at
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Prijs p/s',
                sortCol: 'price_per_amount'
            },
            column: (item) => ({
                children: item.price_per_amount
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Aantal',
                sortCol: 'quantity'
            },
            column: (item) => ({
                children: item.quantity
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Regel totaal'
            },
            column: (item) => ({
                children: (item?.price_per_amount ?? 0) * (item?.quantity ?? 0)
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Levertijd'
            },
            column: (item) => ({
                children: sqlDateDiffToString(item?.sale?.shipped_at, item?.sale?.ordered_at).replace('.', ',')
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Status',
                sortCol: 'status'
            },
            column: (item) => ({
                children: SaleRowStatusTranslation(item?.status)
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'sale-rows' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/sales/${ item.sale_id }`
            } }
            http={ {
                hook: httpHook,
                searchCols: [
                    'sale.id',
                    'sale.customer.company',
                    'sale.customer.first_name',
                    'sale.customer.last_name',
                    'sale.customer.number',
                    'charge.batchcode'
                ],
                with: [ 'sale.customer', 'charge' ],
                sortCol: 'sale_id',
                sortDir: 'DESC',
                filter: {
                    column: 'article_id',
                    operator: '=',
                    value: parentId
                },
                filters: [
                    {
                        displayName: 'Open',
                        column: 'status',
                        value: 'to send'
                    },
                    {
                        displayName: 'Backorder',
                        column: 'status',
                        value: 'backorder'
                    },
                    {
                        displayName: 'Verzonden',
                        column: 'status',
                        value: 'sent'
                    },
                    {
                        displayName: 'Geannuleerd',
                        column: 'status',
                        value: 'canceled'
                    }
                ]
            } }
        />
    </>;
};
export default ArticleSaleRowTable;