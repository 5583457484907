import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { ChargeSaleRowsModel, ChargeSaleRowsRelationsBluePrint } from './ChargeSaleRowTypes';
import useChargeSaleRows from './useChargeSaleRows';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ChargeSaleRowTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useChargeSaleRows(parentId);


    const rows = useMemo((): TableColumns<ChargeSaleRowsModel, ChargeSaleRowsRelationsBluePrint>[] => [
        {
            header: {
                children: 'Ordernummer',
                sortCol: 'sale_id'
            },
            column: (item) => ({
                children: item.sale_id
            }),
            type: 'id'
        },
        {
            header: {
                children: 'Klant',
                sortCol: 'company'
            },
            column: (item) => ({
                children: item.company
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Aantal',
                sortCol: 'quantity'
            },
            column: (item) => ({
                children: item.quantity
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Besteldatum',
                sortCol: 'ordered_at'
            },
            column: (item) => ({
                children: item.ordered_at
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Status',
                sortCol: 'sale_row_status'
            },
            column: (item) => ({
                children: item.sale_row_status
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'sale-rows' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/sales/${ item.sale_id }`
            } }
            http={ {
                hook: httpHook,
                sortCol: 'ordered_at',
                sortDir: 'DESC',
                disableDeArchiving: true,
                filterConfig: {
                    hideArchived: true
                }
            } }
        />
    </>;
};

export default ChargeSaleRowTable;