import React, { FC } from 'react';
import { ExtendTableProps } from '../../../components/table/types/Table';
import BaseChargeTable from '../../charge/BaseChargeTable';


const ProductChargeTable: FC<ExtendTableProps> = ({ parentId }): JSX.Element => {
   return <>
       {parentId && <BaseChargeTable productId={parentId} detailedView={true}/>}
   </>
};

export default ProductChargeTable;