import React, { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../components/table';
import { ExtendTableProps, TableNotification } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import { dispatchHttpEvent } from '../../events/Http.event';
import useAsyncMemo from '../../lib/samfe/hooks/useAsyncMemo';
import { currentSqlDate } from '../../lib/samfe/modules/Parse/Date';
import { batchCodeLabel } from '../charge/ChargeFunctions';
import { BlockedChargeModal } from './BlockedChargeModal';
import BookInForm from './pivot/bookIn/BookInForm';
import PurchaseRowForm from './pivot/purchaseRow/PurchaseRowForm';
import { PurchaseRowDto, PurchaseRowModel, PurchaseRowRelationsBluePrint, PurchaseRowStatusDisplayName, PurchaseRowStatuses } from './pivot/purchaseRow/PurchaseRowTypes';
import usePurchaseRow, { usePurchaseRowResource } from './pivot/purchaseRow/usePurchaseRow';
import SendPurchaseForm from './pivot/sendPurchase/SendPurchaseForm';
import { PurchaseModel } from './PurchaseTypes';
import usePurchase from './usePurchase';


const emptyPurchaseLimit = 20;

type Props = Required<ExtendTableProps>

/**
 *
 * @constructor
 */
const PurchaseTable: FC<Props> = (): JSX.Element => {

    const httpHook = usePurchaseRowResource();
    const purchaseRowHook = usePurchaseRow();
    const openPurchase = usePurchase();

    const [ bookedChargeId, setBookedChargeId ] = useState<number>();
    const [ showBlockedChargeModal, setShowBlockedChargeModal ] = useState(false);


    const emptyPurchases: PurchaseModel[] = useAsyncMemo(async() => {
        return await openPurchase.getList({
            limit: emptyPurchaseLimit,
            select: [ 'id' ],
            orderBy: 'id',
            order: 'DESC',
            doesntHave: [ 'purchaseRows' ],
            filter: 'status=open'
        });
    }, [], []);


    const emptyPurchaseCount = useMemo(() => {
        return openPurchase.pagination.pagination.totalItems ?? 0;
    }, [ openPurchase.pagination.pagination.totalItems ]);


    const notification: TableNotification|undefined = useMemo(() => {

        if (emptyPurchases.length == 0) {
            return undefined;
        }

        const nEmpty = emptyPurchaseLimit>emptyPurchaseCount ?emptyPurchaseCount :emptyPurchaseLimit;
        return {
            title: `Openstaande orders (laatste ${ nEmpty } v/d ${ emptyPurchaseCount } open orders)`,
            variation: 'warning',
            children: <ul>
                { emptyPurchases.map(purchase => <li key={ purchase.id }>
                    Order { purchase.id } heeft nog geen orderregels,&nbsp;
                    <Link className={ 'underline' } to={ `/purchases/${ purchase.id }` }>bekijken</Link>.
                </li>) }
            </ul>
        } as TableNotification;

    }, [ emptyPurchaseCount, emptyPurchases ]);


    const rows = useMemo((): TableColumns<PurchaseRowModel, PurchaseRowRelationsBluePrint>[] => [
        {
            header: {
                children: 'Ordernummer',
                sortCol: 'purchase_id'
            },
            column: (item) => ({
                children: item.purchase_id ?? '[verwijderd]',
                linkTo: (item) => `${ item.purchase_id }`
            }),
            type: 'id'
        },
        {
            header: {
                children: 'Producent'
            },
            column: (item) => ({
                children: item.purchase?.producer?.name,
                linkTo: (item) => `/producers/${ item.purchase?.producer_id }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Product'
            },
            column: (item) => ({
                children: item.article?.product?.name,
                linkTo: (item) => `/products/${ item.article?.product_id }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Artikel'
            },
            column: (item) => ({
                children: item.article?.number,
                linkTo: (item) => `/articles/${ item.article_id }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Artikel producent naam'
            },
            column: (item) => ({
                children: item.articleProducer?.custom_name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Batchcode'
            },
            column: (item) => ({
                children: batchCodeLabel(item.charge),
                linkTo: item.charge_id != undefined ?(item) => `/charges/${ item.charge_id }` :undefined
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Aantal besteld',
                sortCol: 'quantity'
            },
            column: (item) => ({
                children: item.quantity
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Besteldatum'
            },
            column: (item) => ({
                children: item.purchase?.order_date ?? item.sent_at ?? item.created_at
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Week verwacht'
            },
            column: (item) => ({
                children: item.purchase?.week_expected ?? '-'
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'E.T.A. in weken',
                sortCol: 'eta'
            },
            column: (item) => ({
                children: item.status == 'sent' && item.sent_at != null ?(item.eta ?? '-') :'-'
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Overkomstduur in weken',
                sortCol: 'delivery_time'
            },
            column: (item) => ({
                children: item.delivery_time ?? '-'
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Status',
                sortCol: 'status'
            },
            column: (item) => ({
                children: PurchaseRowStatusDisplayName(item?.status)
            }),
            type: 'text'
        }
    ], []);


    return <>
        <Table
            id={ 'purchases' }
            notification={ notification }
            rows={ rows }
            http={ {
                hook: httpHook,
                searchCols: [ 'purchase_id', 'article.number', 'charge.batchcode', 'charge.product.name' ],
                with: [
                    'article.product',
                    'charge',
                    'purchase.producer',
                    'eta',
                    'articleProducer'
                ],
                sortCol: 'purchase_id',
                sortDir: 'DESC',
                filters: PurchaseRowStatuses.map(status => ({
                    column: 'status',
                    displayName: PurchaseRowStatusDisplayName(status),
                    value: status
                }))
            } }
            forms={ {
                edit: {
                    Form: PurchaseRowForm,
                    parentId: (record) => record.purchase_id,
                    id: (record) => record.id,
                    disableForRow: (item) => ![ 'open', 'sent', 'received' ].includes(item.status ?? '')
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.id }`,
                    resourceName: () => 'Inkoopregel',
                    disableForRow: (item) => ![ 'open', 'sent', 'received' ].includes(item.status ?? '')
                },
                actions: [
                    {
                        title: 'Verzenden',
                        hideForRow: (item) => item.status != 'open',
                        parentId: (item) => item.purchase_id,
                        Form: SendPurchaseForm
                    },
                    {
                        title: 'Opnieuw verzenden',
                        hideForRow: (item) => item.status != 'sent',
                        parentId: (item) => item.purchase_id,
                        Form: SendPurchaseForm
                    },
                    {
                        title: 'Inboeken',
                        id: (item) => item.id,
                        parentId: (item) => item.purchase_id,
                        Form: BookInForm,
                        hideForRow: (item) => item.status != 'received',
                        onSuccess: (id) => {
                            setBookedChargeId(id);
                            setShowBlockedChargeModal(true);
                        }
                    }
                ]
            } }
            callbacks={ [
                {
                    title: 'Markeren als ontvangen',
                    hideForRow: (item) => item.status != 'sent',
                    onClick: async(currentItem) => {
                        await purchaseRowHook.updateWithParentId(
                            currentItem?.purchase_id!,
                            currentItem?.id!,
                            {
                                status: 'received',
                                received_at: currentSqlDate()
                            }
                        ).then(dispatchHttpEvent);
                    }
                },
                {
                    title: 'Dupliceren',
                    onClick: async(currentItem) => {
                        const payload: PurchaseRowDto = {
                            article_id: currentItem.article_id!,
                            purchase_id: currentItem.purchase_id!,
                            price_per_amount: parseFloat(`${ currentItem.price_per_amount }`)!,
                            status: currentItem.status !== 'booked' ?currentItem.status! :'sent',
                            quantity: 0
                        };

                        if (currentItem.status !== 'open') {
                            payload.sent_at = currentItem.sent_at;
                        }

                        if (currentItem.status == 'received') {
                            payload.received_at = currentItem.received_at;
                        }

                        if (currentItem.comments !== undefined && currentItem.comments != '') {
                            payload.comments = currentItem.comments;
                        }

                        await purchaseRowHook.createWithParentId(
                            currentItem?.purchase_id!,
                            payload
                        ).then(dispatchHttpEvent);
                    }
                }
            ] }
        />

        { showBlockedChargeModal && bookedChargeId && <BlockedChargeModal
            open={ showBlockedChargeModal }
            setOpen={ setShowBlockedChargeModal }
            chargeId={ bookedChargeId }
            onClose={ () => {
                setBookedChargeId(undefined);
            } }

        /> }
    </>;
};

export default PurchaseTable;