import React, { useEffect, useState } from 'react';
import { dispatchHttpEvent } from '../../events/Http.event';
import { FileModel } from './FileTypes';
import UploadField from './UploadField';
import UploadTable from './UploadTable';


export type ExtraUploadRef = {
    extraColName?: string
    extraColRef?: string
}
export type ExtendUploadProps = ExtraUploadRef&{
    modelName: string,
    fileTypes: string[],
    files?: FileModel[],
    readonly?: boolean
}
export type UploadProps = ExtendUploadProps&{
    modelId?: number
    onSuccess?: () => void
}

const Upload: React.FC<UploadProps> = ({
    modelName,
    files,
    fileTypes,
    onSuccess,
    extraColName,
    extraColRef,
    readonly = false
}): JSX.Element => {

    const [ currentFiles, setCurrentFiles ] = useState(files ?? []);
    const [ newFiles, setNewFiles ] = useState<FileModel[]>([]);
    useEffect(() => {
        setCurrentFiles(files ?? []);
    }, [ files ]);

    return <UploadTable
        staticData={ currentFiles }
        extraColName={ extraColName }
        extraColRef={ extraColRef }
        htmlBefore={ <>
            { !readonly && <UploadField
                modelName={ modelName }
                files={ newFiles ?? [] }
                setFiles={ setNewFiles }
                fileTypes={ fileTypes }
                onSuccess={ () => {
                    if (onSuccess) {
                        onSuccess()
                    } else {
                        dispatchHttpEvent()
                    }
                } }
            /> }
        </> }
    />;
};

export default Upload;