import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import useAsyncMemo from '../../lib/samfe/hooks/useAsyncMemo';
import { classNames } from '../../lib/samfe/modules/Parse/String';
import SortThComponent from './components/stateful/SortTh.component';
import TableForms from './components/stateful/TableForms.component';
import TableComponent from './components/Table.component';
import TbodyComponent from './components/Tbody.component';
import TdComponent from './components/Td.component';
import TheadComponent from './components/Thead.component';
import TrComponent from './components/Tr.component';
import { castData } from './functions/Table.functions';
import useTableColumns from './hooks/UseTableColumns';
import useTableFormStates from './hooks/useTableFormStates';
import useTableHeaders from './hooks/useTableHeaders';
import useTableHttp from './hooks/useTableHttp';
import useTablePagination from './hooks/useTablePagination';
import useTableRows from './hooks/useTableRows';
import { useTableContext } from './providers/Table.provider';
import { TdComponentProps } from './types/TableColumn';


const DataTable: FC = <T extends object, K extends keyof T, R extends string, V = T[K]>(): JSX.Element => {

    const {
        table: {
            rows,
            rowActions,
            rowDisabled,
            rowClassName,
            postProcessData
        }, styling
    } = useTableContext<T, R>();
    const { handleOpenModal, tableFormProps } = useTableFormStates<T, K, R, V>();
    const [pagination, setPagination] = useTablePagination<T, R>()

    const data = useTableHttp<T, R>();

    const processedData = useAsyncMemo(async (): Promise<T[]> => {
        if (!postProcessData) {
            return data;
        }
        const processedData = await postProcessData(data) as T[]
        setPagination({
            ...pagination,
            totalItems: processedData.length
        })
        return processedData;
    }, [ data, postProcessData ], []);

    const tableColumns = useTableColumns<T, R>(rows);
    const tableHeaders = useTableHeaders<T, K, R, V>(tableColumns);
    const tableRows: TdComponentProps[][] = useTableRows<T, K, R, V>(processedData, tableColumns, handleOpenModal);


    const rowLinkTo = useMemo(() => {
        return rowActions?.linkTo;
    }, [ rowActions?.linkTo ]);

    const rowOnClick = useMemo(() => {
        return rowActions?.onClick;
    }, [ rowActions?.onClick ]);

    const rowHasAction = useMemo(() => {
        return rowLinkTo != undefined || rowOnClick != undefined;
    }, [ rowLinkTo, rowOnClick ]);

    const columnCanHaveLink = (column: TdComponentProps) => {
        return rowLinkTo == undefined && column.linkTo != undefined && column.onClick === undefined;
    };

    const columnCanHaveOnClick = (column: TdComponentProps) => {
        return rowOnClick == undefined && column.onClick != undefined && column.linkTo === undefined;
    };

    const columnCanHaveAction = (column: TdComponentProps) => {
        return columnCanHaveLink(column) || columnCanHaveOnClick(column);
    };


    return (
        <>
            <TableComponent className={ 'w-full' }>
                <TheadComponent style={ { top: 0 } }>
                    <TrComponent forSection={ 'head' }>
                        { tableHeaders.map((th, i) => <SortThComponent
                            key={ i }
                            th={ th }
                            position={ i == 0 ?'first' :(tableHeaders.length>1 && i>0 && i == tableHeaders.length - 1 ?'last' :'middle') }
                        />) }
                    </TrComponent>
                </TheadComponent>
                <TbodyComponent>
                    { tableRows.map((columns, rowIndex) => <TrComponent
                        key={ rowIndex }
                        className={ classNames(
                            rowDisabled != undefined && rowDisabled(processedData[rowIndex]) && 'text-gray-400 hover:text-gray-500 hover:bg-opacity-75 ',
                            rowClassName != undefined && rowClassName(processedData[rowIndex])
                        ) }
                    >
                        { columns.map((column, colIndex) => <TdComponent
                                key={ colIndex }
                                type={ column.type }
                                position={ colIndex == 0 ?'first' :(tableHeaders.length>1 && colIndex>0 && colIndex == tableHeaders.length - 1 ?'last' :'middle') }
                                linkTo={ rowLinkTo?.(processedData[rowIndex]) }
                                onClick={ rowOnClick ?() => rowOnClick?.(processedData[rowIndex]) :undefined }
                                className={ classNames(
                                    rowHasAction && column.type != 'action' && column.type != 'sort' && 'hover:cursor-pointer',
                                    rowDisabled != undefined && column.type == 'action' && styling?.variation == 'buttons' && rowDisabled(processedData[rowIndex]) && 'opacity-50',
                                    column.type == 'action' && styling?.variation == 'buttons' && 'align-text-top'
                                ) }
                                style={ column.style }
                            >
                                { rowHasAction && castData(column.children, column.type) }
                                { !rowHasAction && <>
                                    { columnCanHaveLink(column) && <Link className={ 'underline decoration-aqua text-aqua underline-offset-2 decoration-[0.03125rem]' } to={ column.linkTo! }>
                                        { castData(column.children, column.type) }
                                    </Link> }

                                    { columnCanHaveOnClick(column) && <button className={ 'underline decoration-aqua text-aqua underline-offset-2 decoration-[0.03125rem]' } onClick={ column.onClick }>
                                        { castData(column.children, column.type) }
                                    </button> }

                                    { !columnCanHaveAction(column) && castData(column.children, column.type) }
                                    { column.linkTo != undefined && column.onClick !== undefined && 'Only one action supported (linkTo/onClick)' }
                                </> }
                            </TdComponent>
                        ) }
                    </TrComponent>) }
                </TbodyComponent>
            </TableComponent>

            <TableForms { ...tableFormProps }  />
        </>
    );
};

export default DataTable;
