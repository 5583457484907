import React, { FC, useMemo } from 'react';
import Table from '../../../../../components/table';
import { ExtendTableProps } from '../../../../../components/table/types/Table';
import { TableColumns } from '../../../../../components/table/types/TableRow';
import { getRi } from '../../../../../lib/samfe/modules/Parse/Metric';
import { thousandSeparator } from '../../../../../lib/samfe/modules/Parse/Number';
import { CompositionProductModel, CompositionProductRelationsBluePrint } from '../CompositionProductTypes';
import ProductCompoundForm from './ProductCompoundForm';
import useCompositionCompound from './useCompositionCompound';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ProductCompoundTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useCompositionCompound(parentId);

    const getOrReplace = <T extends string|number|boolean|object, P = T|undefined|null>(value: P, fallback: T): T => {
        if (value === undefined || value === null) {
            return fallback;
        }
        switch (typeof value) {
            case 'string':
                return (value.replace(' ', '') !== '' ?value :fallback) as T;
            case 'number':
                return (!isNaN(value) || isFinite(value) ?value :fallback) as T;
            case 'object':
                return (Object.keys(value).length>0 ?value :fallback) as T;
            case 'boolean':
                return (value ?? fallback) as unknown as T;
        }
        return value as unknown as T;
    };


    const rows = useMemo((): TableColumns<CompositionProductModel, CompositionProductRelationsBluePrint>[] => [
        {
            header: {
                children: 'Grondstof'
            },
            column: (item) => ({
                children: `${ item.element?.name } ${ item.element?.compound?.name ?? '' }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Inhoud'
            },
            column: (item) => ({
                children: `${ thousandSeparator(item.amount) } ${ item.unit ?? '[no_unit]' }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'RI'
            },
            column: (item) => ({
                children: getRi(
                    {
                        amount: parseFloat(`${ item.amount ?? 0 }`),
                        unit: getOrReplace(item.unit ?? item.element?.unit, 'mg')
                    },
                    {
                        amount: parseFloat(`${ item.element?.intake ?? 0 }`),
                        // @review evaluate truth of value
                        unit: getOrReplace(item.element?.unit ?? item.unit, 'mg')
                    }
                )
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Categorie'
            },
            column: (item) => ({
                children: item.element?.category?.name
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'composition-compounds' }
            rows={ rows }
            rowSort={ {
                idColumn: 'id',
                orderColumn: 'order'
            } }
            http={ {
                hook: httpHook,
                with: [
                    'element.category',
                    'element.compound',
                    'element.risks'
                ],
                sortCol: 'order',
                sortDir: 'ASC'
            } }
            forms={ {
                edit: {
                    Form: ProductCompoundForm,
                    parentId: (record) => record.product_id,
                    id: (record) => record.id
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.element?.name } ${ record.element?.compound?.name ?? '' }`,
                    resourceName: () => 'Grondstof'
                }
            } }
        />
    </>;
};

export default ProductCompoundTable;