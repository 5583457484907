import React, { FC, ReactNode, useState } from 'react';
import Disclosure from '../../lib/samfe/components/Disclosure/Disclosure';
import Stats from '../../lib/samfe/components/Stats';
import { Tab } from '../../lib/samfe/components/Tab';
import useId from '../../lib/samfe/modules/Router/useId';
import ShowPage from '../../templates/pages/ShowPage';
import ProducerArticleForm from './pivot/producerArticle/ProducerArticleForm';
import ProducerArticleTable from './pivot/producerArticle/ProducerArticleTable';
import ProducerChargeTable from './pivot/producerCharges/ProducerChargeTable';
import ProducerPurchaseRowTable from './pivot/ProducerPurchaseRowTable';
import ProducerStandardForm from './pivot/producerStandard/ProducerStandardForm';
import ProducerStandardTable from './pivot/producerStandard/ProducerStandardTable';
import ProducerForm from './ProducerForm';
import { ProducerModel } from './ProducerTypes';
import useProducer from './useProducer';

const ProducerListItem: FC<{ title: string, children: ReactNode }> = ({ title, children }) => (
  <div className={ 'mx-4 sm:mx-10 2xl:mx-12' }>
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
          <dt className="text-sm font-medium text-gray-500">{ title }</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              { children }
          </dd>
      </div>
  </div>
);

const ProducerShow: FC = (): JSX.Element => {

    const id = useId();
    const [ currentProducer, setCurrentProducer ] = useState<ProducerModel|undefined>(undefined);


    return (
        <ShowPage
            title={ currentProducer?.name }
            breadcrumb={ currentProducer?.name }
            setCurrentModel={ setCurrentProducer }
            FormModal={ ProducerForm }
            httpHook={ useProducer }
            counts={ [ 'purchases', 'articleProducers' ] }
            formActions={ [
                {
                    name: 'Artikel toevoegen',
                    form: ProducerArticleForm,
                    icon: 'add',
                    tabId: 'articles'
                },
                {
                    name: 'Standaard toevoegen',
                    form: ProducerStandardForm,
                    icon: 'add',
                    tabId: 'producer-standards'
                }
            ] }
            tabs={ [
                {
                    name: 'Basisgegevens',
                    id: 'summary',
                    element: 
                    <>
                      <Stats stats={ [
                          {
                              name: 'Bestellingen',
                              value: currentProducer?.purchases_count
                          },
                          {
                              name: 'Artikelen',
                              value: currentProducer?.articleProducers_count
                          },
                          {
                              name: 'Is actief',
                              value: !currentProducer?.archived && currentProducer?.active ?'Ja' :'Nee'
                          }
                      ] }/>

                      <Disclosure defaultOpen={ true } title={ 'Klantgegevens' }>
                        <ProducerListItem title={ 'Contact' }>
                            <span className={ 'font-medium' }>{ currentProducer?.number }</span><br/>
                            <span className={ 'font-medium' }>{ currentProducer?.contact_person }</span><br/>
                            <a href={ `mailto:${ currentProducer?.email }` }>{ currentProducer?.email }</a><br/>
                            <a href={ `mailto:${ currentProducer?.email_alt }` }>{ currentProducer?.email_alt }</a><br/>
                            <a href={ `tel:${ currentProducer?.phone }` }>{ currentProducer?.phone }</a><br/>
                        </ProducerListItem>
                        <ProducerListItem title={ 'Adres' }>
                            <span className={ 'font-medium' }>{ currentProducer?.address }</span><br/>
                            <span className={ 'font-medium' }>{ currentProducer?.zipcode }</span><br/>
                            <span className={ 'font-medium' }>{ currentProducer?.city }</span><br/>
                            <span className={ 'font-medium' }>{ currentProducer?.website }</span><br/>
                        </ProducerListItem>
                        <ProducerListItem title={ 'Notities' }>
                            <span className={ 'font-medium' }>{ currentProducer?.comments }</span><br/>
                        </ProducerListItem>

                    </Disclosure>
                  </>
                },
                {
                    name: 'Artikelen',
                    id: 'articles',
                    element: <ProducerArticleTable parentId={ id }/>
                },
                {
                    name: 'Charges',
                    id: 'charges',
                    element: <ProducerChargeTable parentId={ id }
                    />
                },
                {
                    name: 'Bestellingen',
                    id: 'purchases',
                    element: <ProducerPurchaseRowTable parentId={ id }/>
                },
                {
                    name: 'Industriestandaarden',
                    id: 'producer-standards',
                    element: <ProducerStandardTable parentId={ id }/>
                }
            ] as Tab[] }
        />
    );
};
export default ProducerShow;