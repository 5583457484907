import moment from 'moment';
import React, { FC, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { DefaultTableFilter } from '../../components/table/types/TableFilter';
import { TableColumns } from '../../components/table/types/TableRow';
import { parseChargeLocations } from '../../lib/samfe/modules/Parse/Location';
import useFileHandler from '../document/useFileHandler';
import ProfitLossForm from '../profitLoss/ProfitLossForm';
import ChargeForm from './ChargeForm';
import { batchCodeLabel, chargeTableFilters, expirationDateLabel } from './ChargeFunctions';
import { ChargeModel, ChargeRelationsBluePrint } from './ChargeTypes';
import useCharge from './useCharge';


const ChargeOriginColumn: FC<{ charge: ChargeModel }> = ({ charge }) => {

    const hasProducer = useMemo(() => {
        return charge.purchaseRow?.purchase?.producer != undefined
            && charge.purchaseRow.purchase.producer.name != 'uitvulopdracht (oude GA)';
    }, [ charge.purchaseRow?.purchase?.producer ]);

    return hasProducer
           ?<>{ charge.purchaseRow?.purchase?.producer?.name ?? 'Onbekend' } </>
           :<>uitvulopdracht ({ charge.fromRepackOrders?.[0]?.id ?? 'oude GA' })</>;
};


type Props = Omit<ExtendTableProps, 'parentId'>&{
    productId?: number,
    articleId?: number,
    detailedView?: boolean
}

const BaseChargeTable: FC<Props> = ({
    productId,
    articleId,
    detailedView = false,
    ...props
}) => {

    const httpHook = useCharge();
    const { getDocument } = useFileHandler();

    const rows = useMemo((): TableColumns<ChargeModel, ChargeRelationsBluePrint>[] => {
        const rows: TableColumns<ChargeModel, ChargeRelationsBluePrint>[] = [];

        if (!articleId) {
            rows.push({
                header: {
                    children: 'Artikelnummer'
                },
                column: (charge) => ({
                    children: charge.article?.number
                }),
                type: 'id'
            });
        }

        if (!articleId && !productId) {
            rows.push({
                header: {
                    children: 'Naam'
                },
                column: (charge) => ({
                    children: charge?.product?.name
                }),
                style: {
                    minWidth: '8rem'
                },
                type: 'text'
            });
        }

        rows.push(
            {
                header: {
                    children: 'Batchcode',
                    sortCol: 'batchcode'
                },
                column: (charge) => ({
                    children: batchCodeLabel(charge)
                }),
                type: 'text'
            },
            {
                header: {
                    children: 'Versie'
                },
                column: (charge) => ({
                    children: charge.product?.version ?`V${ charge.product?.version }` :undefined
                }),
                type: 'text'
            },
            {
                header: {
                    children: 'THT',
                    sortCol: 'expiration_date'
                },
                column: (charge) => ({
                    children: expirationDateLabel(charge.expiration_date, true)
                }),
                type: 'text'
            },
            {
                header: {
                    children: 'Leverancier'
                },
                column: (charge) => ({
                    children: <ChargeOriginColumn charge={ charge }/>
                }),
                type: 'element'
            }
        );

        if (!detailedView) {
            rows.push({
                header: {
                    children: 'Locatie'
                },
                column: (charge) => ({
                    children: parseChargeLocations(charge.chargeLocations ?? [])
                }),
                type: 'text'
            });
        }

        if (detailedView) {
            rows.push(
                {
                    header: {
                        children: <span className={'text-right'}>
                            <span className={'block -mb-1'}>Prijs p/s</span>
                            <small>x1.000 voor bulk</small>
                        </span>,
                        sortCol: 'price_per_amount'
                    },
                    column: (charge) => ({
                        children: (charge.price_per_amount ?? 0) * (charge.article?.is_bulk ?1000 :1)
                    }),
                    type: 'currency'
                },
                {
                    header: {
                        children: 'Aantal ingeboekt'
                    },
                    column: (charge) => ({
                        children: charge.purchaseRow?.booked_quantity
                    }),
                    type: 'numeric'
                }
            );
        }

        rows.push(
            {
                header: {
                    children: 'Rekvoorraad',
                    sortCol: 'amount'
                },
                column: (charge) => ({
                    children: charge.amount
                }),
                type: 'numeric'
            }
        );

        if (detailedView) {
            rows.push(
                {
                    header: {
                        children: 'Te versturen',
                    },
                    column: (charge) => ({
                        children: charge.samfeStock?.open_sales
                    }),
                    type: 'numeric'
                },
                {
                    header: {
                        children: 'Uitvul opdrachten',
                    },
                    column: (charge) => ({
                        children: charge.samfeStock?.open_for_repacking
                    }),
                    type: 'numeric'
                }
            )
        }

        rows.push({
                header: {
                    children: 'Beschikbaar (na uitvul opdrachten)'
                },
                column: (charge) => ({
                    children: charge.samfeStock?.usable_stock
                }),
                type: 'numeric'
            }
        );

        if (!detailedView) {
            rows.push(
                {
                    header: {
                        children: 'Ontvangstdatum',
                        sortCol: 'received_date'
                    },
                    column: (charge) => ({
                        children: charge.received_date
                    }),
                    type: 'date'
                },
                {
                    header: {
                        children: 'Boekingsnummer',
                        sortCol: 'packaging_slip'
                    },
                    column: (charge) => ({
                        children: charge.packaging_slip
                    }),
                    type: 'text'
                }
            );
        }
        if (detailedView) {
            rows.push({
                header: {
                    children: 'Locatie'
                },
                column: (charge) => ({
                    children: parseChargeLocations(charge.chargeLocations ?? [])
                }),
                type: 'text'
            });
        }
        return rows;
    }, [ articleId, productId, detailedView ]);

    const filter = useMemo(() => {
        if (productId != undefined) {
            return {
                column: 'product_id',
                operator: '=',
                value: productId
            } as DefaultTableFilter<ChargeModel>;
        }
        if (articleId != undefined) {
            return {
                column: 'article_id',
                operator: '=',
                value: articleId
            } as DefaultTableFilter<ChargeModel>;
        }
        return undefined;
    }, [ productId, articleId ]);

    const filters = useMemo(() => {
        return chargeTableFilters();
    }, []);

    return (<>
            <Table
                { ...props }
                id={ 'charges' }
                rows={ rows }
                rowDisabled={ (item) => (item.amount ?? 0)<=0 || moment(item.expiration_date).isBefore(new Date()) }
                rowActions={ {
                    linkTo: (item) => `/charges/${ item.id }`
                } }
                http={ {
                    hook: httpHook,
                    with: [
                        'purchaseRow.purchase.producer',
                        'parent',
                        'chargeLocations.location.group',
                        'samfeStock',
                        'article',
                        'product',
                        'assessments',
                        'fromRepackOrders'
                    ],
                    searchCols: [ 'batchcode', 'packaging_slip', 'article.number', 'expiration_date' ],
                    sortCol: 'expiration_date',
                    sortDir: 'DESC',
                    filter,
                    filters,
                    filterConfig: {
                        hideArchived: true
                    },
                    disableDeArchiving: true
                } }
                callbacks={
                    [
                        {
                            title: 'COA',
                            onClick: (record) => getDocument(record.id, 'coa')
                        }
                    ]
                }
                forms={ {
                    edit: {
                        Form: ChargeForm,
                        id: (record) => record.id
                    },
                    actions: [
                        {
                            title: 'Mutatie toevoegen',
                            Form: ProfitLossForm,
                            parentId: (item) => item.id
                        }
                    ]
                } }
            />
        </>
    );
};

export default BaseChargeTable;
