import React, { FC } from 'react';
import { ExtendTableProps } from '../../../components/table/types/Table';
import BaseChargeTable from '../../charge/BaseChargeTable';

type Props = Omit<Required<ExtendTableProps>, 'notification'>
const ArticleChargeOverviewTable: FC<Props> = ({ parentId, htmlBefore }): JSX.Element =>{

    return <>
        <BaseChargeTable articleId={ parentId } htmlBefore={htmlBefore} detailedView={true} />
    </>
};
export default ArticleChargeOverviewTable;
