import React, { FC, useCallback, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import { ucFirst } from '../../lib/samfe/modules/Parse/String';
import WooCommerceImportForm from './import/WooCommerceImportForm';
import useWooCommerce from './useWooCommerce';
import { GetWcImportStatusTranslation, WooCommerceModel, WooCommerceOrder, WooCommerceRelationsBluePrint } from './WooCommerceTypes';


type Props = Required<ExtendTableProps>

const WooCommerceTable: FC<Props> = () => {

    const httpHook = useWooCommerce();

    const rows = useMemo((): TableColumns<WooCommerceModel, WooCommerceRelationsBluePrint>[] => [
        {
            header: {
                children: 'WC Ordernummer',
                sortCol: 'wc_order_id'
            },
            column: (item) => ({
                children: item.wc_order_id
            }),
            type: 'id'
        }, {
            header: {
                children: 'Klant'
            },
            column: (item) => {
                const parsedItem = item.tableWcOrder

                let recipient = parsedItem?.shipping?.company ?? parsedItem?.billing?.company;
                if (!recipient) {
                    const firstName = ucFirst(parsedItem?.shipping?.first_name ?? parsedItem?.billing?.first_name);
                    const lastName = ucFirst(parsedItem?.shipping?.last_name ?? parsedItem?.billing?.last_name);
                    const spacer = firstName.length>0 && lastName.length>0 ?' ' :'';
                    recipient = `${ firstName }${ spacer }${ lastName }`;
                }

                let res =  `${recipient}`

                const customerNumber = parsedItem.acf_klantnummer
                if (customerNumber) {
                    res = res.concat(` (${customerNumber})`)
                }

                return {
                    children: res
                }
            },
            type: 'text'
        }, {
            header: {
                children: 'Bedrag'
            },
            column: (item) => ({
                children: item.tableWcOrder.total
            }),
            type: 'currency'
        }, {
            header: {
                children: 'Status',
                sortCol: 'status'
            },
            column: (item) => ({
                children: GetWcImportStatusTranslation(item.status)
            }),
            type: 'text'
        }, {
            header: {
                children: 'Orderdatum'
            },
            column: (item) => ({
                children: item.tableWcOrder.date_created
            }),
            type: 'date'
        }
    ], []);


    const postProcessData = useCallback((items: WooCommerceModel[]) => {
        return items.map(item => ({
            ...item,
            tableWcOrder: JSON.parse(item.json ?? '{}') as Partial<WooCommerceOrder>
        }))
    }, []);

    return (
        <Table
            id={ 'woocommerce-import' }
            rows={ rows }
            postProcessData={postProcessData}
            http={ {
                hook: httpHook,
                searchCols: [ 'status', 'id' ],
                sortCol: 'wc_order_id',
                sortDir: 'DESC',
                filters: [
                    {
                        default: true,
                        displayName: 'Nieuw',
                        column: 'status',
                        operator: '=',
                        value: 'open'
                    },
                    {
                        displayName: 'Verwerkt',
                        column: 'status',
                        operator: '=',
                        value: 'processed'
                    }
                ],
            } }
            forms={ {
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.wc_order_id }`,
                    resourceName: () => 'WooCommerce order',
                    disableForRow: (record) => record.status !== 'open'
                },
                actions: [
                    {
                        Form: WooCommerceImportForm,
                        title: 'Importeren',
                        id: (record) => record.id,
                        disableForRow: (record) => record.status !== 'open',
                        onSuccess: () => {}
                    }
                ]
            } }
        />
    );
};

export default WooCommerceTable;
